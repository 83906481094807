.bg-usfulltips{
    background: rgba($primary-color, .04);
    padding: $padding5*3;
    @include border-radius(5px);
    border: 1px solid #e1e0d0; 
    width:$width-full;color: $black-54;
    ul {
        padding: $padding0;
        list-style: none;
        li{padding-left: $padding5*7;
            background-image: url(../images/tick.png);
            background-repeat: no-repeat;
            margin-bottom: $margin-bottom10; line-height: $line-height-base/1.1;font-size: $font14; color: $black-54;
        // &:last-child{background-image:none;}
    }
    }
}
.addStock_DC{
    h1{font-size: $font14*2; padding-top:$padding5*3;}
    h6{font-size: $font14; padding-top:$padding5*3;}
    label{font-size: $font12; color: $black-54;
    &.gs_control{font-size: $font14; color: $black-87;}
}
i{font-size: $font12; color:$black-54;
&.notes{ display: $display-block; margin-top: -$margin-bottom20;
    margin-bottom: $margin-bottom30; padding-left: $padding5*4;}
}
    }
.fileuploadbox{
    .dropzone{border:dashed 2px $black-12; min-height: 280px; background: rgba($black, 0.02); @include border-radius(5px); padding: $padding5*7 $padding5*3 $padding5*3 $padding5*3; text-align: $text-center; @include transition(all 0.3s);
        
        
        &:hover{border:dashed 2px $black-54; @include box-shadow(0px 3px 8px 0px rgba($black,0.3))}
        p{text-align: $text-center; margin-bottom:$margin0; }
    }
    .deleteImg, .rotateImg{position: $position-absolute;top: -4px; color:$white;
        right: -4px;
        @include border-radius(50%); background: $black-54; width:16px;height: 16px; text-align: $text-center;line-height: 16px;
        i{color:$white;}
    }
        .rotateImg{right:inherit;left: -2px;background: $black;}
       
        aside{
            .List{ position: relative;
                &:after{ display: none;
                    content: "Profile Photo";
                        position: absolute;
                        top: 0;
                        left: 0;
                        background:$success-color;
                        // url(../images/profile-tag.png) no-repeat
                        width: 80px;
                        height: 18px;
                        padding: $padding0+2 $padding5;
                        font-size: $font12;
                        color: $white;
                        @include border-radius(0 2px 2px 0);
                }
        
        .draggable{
            >div>div{cursor: move;}

            .deleteImg{cursor: pointer;
            &:hover{background: $black}
        }
            
            &.dragged{
                &:after{display: none;}
            }
            &:hover{
                > div{
                    border: dashed 1px $black-54 !important;
                }
            }
        }    
    }
            + p.text-light{display: none;}
        }
        
&.updated{
    .dropzone{min-height: 30px; padding: $padding5*3;}
    aside{
        .List{ 
            &:after{ display: block;}
        }
        + p.text-light{display: block;}
    }
    
    
}      
        

 
.dragged {
    opacity: 0.7;
}
 
.placeholder {
    @include transition (all 0.3s);
  opacity: 0.5;
  background: $black-12;
}     
}
.pointer-list{
    display: flex; flex: 0 1 auto;
    align-items: flex-start;
    justify-content: space-around;

    li{ padding:0 15px;
        &:first-child{padding-left: 0px;}
        &:last-child{padding-right: 0px;}
    .pointer-detail{display: $display-block; margin-bottom: $margin0; color: $black-87;} 
    .pointer-label{ font-size: $font12; color: $black-54;}
    }
}
// @media(min-resolution: 144dpi), (-webkit-min-device-pixel-ratio: 1.5){
//     .fileuploadbox{
//     #List1{
//         > div{
//             &:first-child{
//                 &:after{
//                     background: url(../images/profile-tag2x.png) no-repeat;
//                     background-size: 72px 71px;
//                 }
//             }
//         }
//         .draggable{cursor: move;
//         &:hover{
//            > div{
//                 border: dashed 1px $black-54 !important;
//             }
//         }
//     }
        
//     } 
// }   
// }
