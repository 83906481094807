.signUp-container {
  min-height: 340px;
  h1 {
    margin-bottom: 5px;
  }
  p {
    color: $black-54;
    margin-bottom: $margin-bottom10;
    &.login-p {
      margin-bottom: 30px;
      &.smtxt {
        font-size: $font14;
      }
    }
  }
  .forgotP {
    float: $float-right;
    color: $primary-color;
    font-size: $font12;
    font-weight: 500;
  }
  .dc-remember {
    color: $black-54;
    font-size: $font12;
  }
}
.mrg-b30 {
  margin-bottom: $margin-bottom30;
}
.btn {
  &.button-rounded {
    padding: 0 25px;
    font-size: $font14;
    color: $black-54;
    background: rgba($headerbg, 0.12);
    @include border-radius(30px);
    font-size: $font14;
    box-shadow: none;
    text-transform: capitalize;
    height: 40px;
    margin-right: 7px;
    outline: none;
    &.active {
      background: $headerbg;
      color: $white;
    }
  }
}
.editmobile {
  position: $position-absolute;
  top: 10px;
  font-size: $font14;
  right: 15px;
  color: $primary-color;
}

/* contact */
.contactInfo {
  padding: 10px 0px 20px;
  font-size: 18px;
  width: 80%;
}
.contact_Ic {
  border-radius: 50%;
  background: rgba($headerbg, 0.12);
  text-align: $text-center;
  float: $float-left;
  margin-right: 20px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin-bottom: 30px;
}

.phonewithcountry {
  padding: 1px;
  display: flex;
  width: 100%;
}
.phonewithcountry .countrycode {
  width: 97px;
  padding-top: 23px;
}
.phonewithcountry .countrymobile {
  display: flex;
  padding-left: 10px;
  width: 100%;
}
.phonewithcountry .countrymobile .form-field {
  width: 100%;
}
